import React, { useEffect, useState } from 'react';
import { IonSkeletonText, IonThumbnail,IonLabel,IonAvatar, IonChip ,IonBackButton,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonList, IonImg } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import { useHistory } from 'react-router-dom';
import './items/Category.css'
import { SearchObjects } from '../services/Api';
import {Helmet} from "react-helmet";

const Search: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const [items, setItems] = useState([]);
  const [sterm, setSterm] = useState("");
  const queryParameters = new URLSearchParams(window.location.search)
  const searchTerm = queryParameters.get("s")
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const loadingItems = [0,1,2,4,5,6,7,8];

  
  const onSearch = async (str:string) => {
    setLoading(true);
    setSterm(str);
    SearchObjects(str).then((resp:any) => {
        console.log(resp);
        setItems(resp);
        setLoading(false);
    });
  };

  const viewItem = (item:any) => {
    let cat = '';
    if(item.category_slug){
        cat = item.category_slug+"/"+item.name;
    }else{
        cat = item.name;
    }
    history.push("/"+item.parent_slug+"/"+cat)
  };

  const getClassName = (item:any) => {
    if(item.rarity){
      return item.rarity
    }

    return 'loading';
  }

  useEffect(() =>{
    if(searchTerm){
      onSearch(searchTerm);
    }
  },[searchTerm]);

  return (
    <IonPage>
      <Helmet>
            <meta charSet="utf-8" />
            <title>{'BlockBook Guide | Search'}</title>
            <meta name="description" content="Search through all the items, weapons, tools, resources, and mobs within the Fortnite Lego universe using Block Book Guide"></meta>

        </Helmet>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

            <IonButtons slot="end">
                <IonBackButton></IonBackButton>
            </IonButtons>
          {/* <IonTitle>Home</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            {/* <IonTitle size="large">Home</IonTitle> */}
          </IonToolbar>
        </IonHeader>
        


        <IonList>
            <IonItem>
                <IonInput label="Search:" placeholder="Wood, Frost Wood, Marble"
                value={sterm}
                onIonInput={(e) => {
                    if(e.detail.value){
                        if(e.detail.value.length > 1){
                            onSearch(e.detail.value);
                        }
                    }
                }}></IonInput>
            </IonItem>


            {loading ? (
              <>
              {loadingItems.map((item:any, p:number) => (
                <IonItem className='item--loading' key={'placeholder'+p}>
                  <IonThumbnail slot="start">
                    <IonSkeletonText animated={true} style={{borderRadius:'50px'}}></IonSkeletonText>
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated={true} style={{ width: '80%', height:'18px' }}></IonSkeletonText>
                    </h3>
                    <p>
                      <IonSkeletonText animated={true} style={{ width: '50px', height: '19px', borderRadius:'50px' }}></IonSkeletonText>
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
              </>
            ) : (
              <>
              {items.map((item:any, i:number) => (
                <IonItem 
                  button 
                  key={'search'+i}
                  detail={true}  
                  onClick={() => {
                    viewItem(item)
                  }}
                  className={"item--"+getClassName(item)}
                  
                >
                    <IonAvatar slot="start"  className={"avatar-item avatar--"+item.rarity}>
                    {item.icon ? (
                      <IonImg alt={item.title} src={item.icon} />
                    ) : (
                      <IonImg alt={item.title} src="/assets/missing.png" />
                    )}
                    </IonAvatar>
                    
                    <IonLabel>
                    

                      <h4>{item.title}</h4>
                      {item.rarity && (
                      <IonChip className={"bb-chip bb-chip--"+item.rarity}>{item.rarity[0].toUpperCase() + item.rarity.substring(1)}</IonChip>

                      )}
                    </IonLabel>
                  </IonItem>
              ))}
              </>
            )}

          
          
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Search;
