import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Redirect } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import ParentCategoryPage from './pages/items/ParentCategory';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import CategoryPage from './pages/items/Category';
import SinglePage from './pages/items/Single';
import MobCategoryPage from './pages/mobs/Category';
import SingleMobPage from './pages/mobs/Single';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import Search from './pages/Search';
import BuildingCategoryPage from './pages/buildings/Category';
import SingleBuildingPage from './pages/buildings/Single';
import AboutPage from './pages/general/About';
import PostCategoryPage from './pages/posts/Category';
import SinglePostPage from './pages/posts/Single';
import PrivacyPolicy from './pages/Privacy';
import TermsAndConditions from './pages/Terms';
import Page404 from './pages/Page404';
setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      
      <IonReactRouter >
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main" placeholder={{}}>
          
            
            <Route path="/" exact={true}>
              <Home />
            </Route>

            <Route path="/search" exact={true}>
              <Search />
            </Route>

            <Route path="/about" exact={true}>
              <AboutPage />
            </Route>

            

            <Route path="/items" exact={true}>
              <ParentCategoryPage name={"Items"} slug={'items'}/>
            </Route>
            
            <Route path="/items/:category" exact={true}>
              <CategoryPage name={"Items"} slug={'items'} />
            </Route>
            
            <Route path="/items/:category/:name" exact={true}>
             <SinglePage />
            </Route>


            <Route path="/mobs" exact={true}>
              <MobCategoryPage name={"Mobs"} slug={'mobs'} />
            </Route>
            <Route path="/mobs/:category/:name" exact={true}>
              <SingleMobPage />
            </Route>

            <Route path="/buildings" exact={true}>
              <BuildingCategoryPage name={"Buildings"} slug={'buildings'}/>
            </Route>

            <Route path="/buildings/:category/:name" exact={true}>
              <SingleBuildingPage />
            </Route>

            <Route path="/tips" exact={true}>
              <ComingSoon />
            </Route>

            <Route path="/tips/:category" exact={true}>
              <Page />
            </Route>

            <Route path="/tips/:category/:name" exact={true}>
              <Page />
            </Route>


            <Route path="/news" exact={true}>
              <PostCategoryPage name="News" slug="news"/>
            </Route>

            <Route path="/news/:name" exact={true}>
              <SinglePostPage />
            </Route>


            <Route path="/news/:name" exact={true}>
              <SinglePostPage />
            </Route>


            <Route path="/privacy-policy" exact={true}>
              <PrivacyPolicy/>
            </Route>
            <Route path="/terms-and-conditions" exact={true}>
              <TermsAndConditions/>
            </Route>
            <Route path="/404">
              <Page404 />
            </Route>

            <Redirect to="/404" />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
