import React from "react";
import { IonBackButton, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle , IonIcon, IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import '../Page.css';
import {Helmet} from "react-helmet";

import { search } from 'ionicons/icons';
const Card = (data:{title:string, body:string}) => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{data.title}</IonCardTitle>
        {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
      </IonCardHeader>

        <IonCardContent>
        {data.body}
        </IonCardContent>
    </IonCard>
  )
}
const AboutPage = () => {
    const name = 'About Block Book Guide';
    

    
    return (
        <IonPage>
          <Helmet>
        <meta charSet="utf-8" />
        <title>{'Block Book Guide | About us'}</title>
    </Helmet>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton></IonBackButton>
            </IonButtons>
            <IonTitle>{name}</IonTitle>
  
            <IonButtons slot="end">
            
            <IonButton routerLink={"/search"}>
                    <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
                </IonButton>
                <IonMenuButton />
          </IonButtons>
          </IonToolbar>
        </IonHeader>
  
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{name}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className='cont'>
            <IonGrid>
              <IonRow>
                <IonCol size='12'>
                  <Card title="What is Block Book Guide" body="Block Book Guide is a app used as a quick source of information while playing Fortnite Lego. We are not associated with Fortnite, Epic Games or Lego in anyway. All data is provided for informative purposes and belongs to them. "/>
                </IonCol>
                <IonCol size='12'>
                  <Card title="How & Who?" body="We use a combination of tools to provide this app. This includes Ionic Framework, React, Wordpress and FModel. It is maintained by Infiny Labs"/>
                </IonCol>
                
              </IonRow>
            </IonGrid>

          </div>
        </IonContent>
      </IonPage>
    );
};


export default AboutPage;