import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
const container = document.getElementById('root');
import Plausible from 'plausible-tracker'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion

const { trackPageview } = Plausible({
  domain: 'blockbook.guide',
  apiHost:'https://analytics.jebari.us'
})


if(typeof location !== 'undefined'){
  trackPageview();
}


const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />

    
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
