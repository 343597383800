// src/SwiperComponent.jsx

import React, { useEffect, useState } from 'react';
import { IonSkeletonText, IonButton } from '@ionic/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Banner.css'
import { Pagination, Navigation  } from 'swiper/modules';
import { GetHomeObject } from '../../services/Api';
const BannerComponent = () => {
  const [banners, setBanners] = useState<Array<any>>([]);
  const [isLoading, setIsLoding] = useState(true);




  

  const getData = async () => {
    try {
      GetHomeObject().then((r:any) => {
        setBanners(r)
        setIsLoding(false);
      });
    } catch(e){
      if (typeof e === "string") {
          alert(e.toUpperCase()); 
      } else if (e instanceof Error) {
          alert(e.message); // works, `e` narrowed to Error
      }
  }
    
  }

  useEffect(() => {
    getData();
  },[]);
  return (
    <>

    {isLoading ? (
      <IonSkeletonText animated={true} style={{ width: '100%', height:'280px', margin:0, maxWidth:'100%', borderRadius:'4px' }}></IonSkeletonText>
    ) : (
      <>
      <Swiper
        slidesPerView={1}
        className="swiper-container"
        modules={[Pagination, Navigation]}
        spaceBetween={10}
        loop={true}
        navigation={true}
      >

        {banners.map((banner:any, index:number) => (
          <SwiperSlide key={'banner'+index}>
            <div className="banner-card" style={{backgroundImage:'url('+banner.image+')'}}>
              <span className="banner-card__title">{banner.title}</span>
              <span className="banner-card__subtitle">{banner.subtitle}</span>

              {banner.button_url.length > 0 && (
                <>
                {banner.open_in_new_window ? (
                        <IonButton 
                          href={banner.button_url}
                          target='_blank'
                          >
                        {banner.button_text}
                      </IonButton>
                      ) : (

                    <IonButton 
                    routerLink={banner.button_url}
                    >
                      {banner.button_text}
                  </IonButton>
                )}
              </> 
            )}



              
              
            </div>
            </SwiperSlide>
        ))}
      </Swiper>
      </>
    )}
      
    </>
  );
};

export default BannerComponent;
