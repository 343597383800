import React, { useState, useEffect } from 'react';
import { IonButton, IonIcon , IonAvatar, IonChip ,IonItemDivider, IonItemGroup, IonItem, IonLabel, IonList, IonListHeader, IonBackButton, IonSkeletonText, useIonViewDidEnter,useIonViewWillLeave, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import '../Page.css';
import { GetObjects } from '../../services/Api';
import './Category.css';
import {Helmet} from "react-helmet";
import { search } from 'ionicons/icons';
import NotAssociated from '../../components/NotAssociated';

interface CategoryPageProps{
  name:string,
  slug:string
}

type Item = {
  name: string;
  data:ItemData;
  rarity: 'common' | 'uncommon' | 'rare' | 'epic';
};

type ItemData = {
  rarity: 'common' | 'uncommon' | 'rare' | 'epic';
}

type RarityOrder = {
  [key in Item['rarity']]: number;
};

const rarityOrder: RarityOrder = {
  common: 0,
  uncommon: 1,
  rare: 2,
  epic: 3,
};

const sortByRarity = (a: Item, b: Item): number => {
  return rarityOrder[a.data.rarity] - rarityOrder[b.data.rarity];
};

const CategoryPage = (props:CategoryPageProps) => {
    const { category } = useParams<{ category: string; }>();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [catData, setCatData] = useState<any>({});
  const history = useHistory();

  const getItems = () => {
      const slug = category;
      console.log('Getting '+slug)
      GetObjects(slug).then((data:any) => {
        setIsLoading(false);
        const arr:any = Object.values(data);
        setData(arr);
        sortData(arr);
      })
  };

  const sortData = (d:any) => {
    const categorizedData: { [key: number]: any } = {};
    d.forEach((element:any) => {
      if (!categorizedData[element.cat.term_id]) {
        // If not, create a new array with the category name as the key
        categorizedData[element.cat.term_id] = {
          label:element.cat.name,
          id:element.cat.term_id,
          items:[]
        };
      }

      categorizedData[element.cat.term_id].items.push(element);
    });
    console.log(categorizedData);
    d.forEach((element:any) => {
      categorizedData[element.cat.term_id].items.sort(sortByRarity);
    });
    
    setCatData(categorizedData);
  };

  

  const viewCategory = (slug:any) => {
    history.push("/"+props.slug+"/"+category+"/"+slug.post_name)
  };

//   useIonViewWillLeave(() => {
//     setData([]);
//     setIsLoading(true);
//   });
  useEffect(() => {
    getItems();
    console.log('ionViewDidEnter event fired');
  },[]);
  return (
    <IonPage>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{'BlockBook Guide | ' + category[0].toUpperCase() + category.substring(1)}</title>
          <meta name="description" content={'Read more about ' + category[0].toUpperCase() + category.substring(1) + 's in Fortnite Lego on Blockbook guide'}></meta>

      </Helmet>
      <IonHeader >
        <IonToolbar>
          
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>{category[0].toUpperCase() + category.substring(1)}</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink={"/search"}>
                <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
            </IonButton>
            <IonMenuButton />
        </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
            <IonButtons slot="start">
                
            </IonButtons>
          <IonToolbar>
            <IonTitle size="large">Items</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='cont'>

        {isLoading ? (
          <IonSkeletonText animated={true} style={{ width: '100%', height:300 }}></IonSkeletonText>
        ) : (
          <>




<IonList >
      { Object.keys(catData).map((item, i) => (
        
          <IonItemGroup key={i}>
            
            <IonListHeader>
              <IonLabel><h2 style={{fontWeight:'bold'}}>{catData[item].label}</h2></IonLabel>
            </IonListHeader>
            {catData[item].items.map((d:any,ind:number) =>
              <IonItem 
              button 
              key={ind}
              detail={true}  
              onClick={() => {
                viewCategory(d)
              }}
              className={"item--"+d.data.rarity}>
                <IonAvatar slot="start" className={"avatar-item avatar--"+d.data.rarity}>
                  {d.data.thumbnail ? (
                      <IonImg alt={d.post_title} src={d.data.thumbnail} />
                    
                  ) : (
                    <IonImg alt={d.post_title} src="/assets/missing.png" />
                  )}
                </IonAvatar>
                <IonLabel>
                

                  <h4>{d.post_title}</h4>
                  <IonChip className={"bb-chip bb-chip--"+d.data.rarity}>{d.data.rarity[0].toUpperCase() + d.data.rarity.substring(1)}</IonChip>
                </IonLabel>
              </IonItem>
            )}
            <IonItemDivider></IonItemDivider>
            </IonItemGroup>
        
      ))}       
          </IonList>
          </>
          
        )}
        
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CategoryPage;
