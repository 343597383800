import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import {
  IonContent,
  IonIcon,
  IonBadge,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { newspaperSharp,constructSharp, heartOutline, heartSharp, homeSharp, pizzaSharp, flaskSharp, informationCircleSharp } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  comingSoon?:boolean;
}

const appPages: AppPage[] = [
  {
    title: 'Items',
    url: '/items',
    iosIcon: pizzaSharp,
    mdIcon: pizzaSharp
  },
  {
    title: 'Mobs',
    url: '/mobs',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  },
  {
    title: 'Buildings',
    url: '/buildings',
    comingSoon:false,
    iosIcon: constructSharp,
    mdIcon: constructSharp
  },
  {
    title: 'Tips & Tricks',
    url: '/tips',
    comingSoon:true,
    iosIcon: flaskSharp,
    mdIcon: flaskSharp
  }
];

const appPages2: AppPage[] = [
  {
    title: 'News',
    url: '/news',
    comingSoon:false,
    iosIcon: newspaperSharp,
    mdIcon: newspaperSharp
  },
  {
    title: 'About us',
    url: '/about',
    comingSoon:false,
    iosIcon: informationCircleSharp,
    mdIcon: informationCircleSharp
  }
];


const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay" >
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader><Logo style={{maxHeight:"55px", float:'left', marginBottom:"15px" }}/></IonListHeader>
          <IonNote>The Unofficial Fortnite Lego Guide Book</IonNote>
          
          <IonMenuToggle autoHide={false}>
            <IonItem className={location.pathname === '/' ? 'selected' : ''} routerLink={'/'} routerDirection="none" lines="none" detail={false}>
              <IonIcon aria-hidden="true" slot="start" ios={homeSharp} md={homeSharp} />
              <IonLabel>Home</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={(location.pathname.includes(appPage.url) && appPage.url !== '/') ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                  {appPage.comingSoon && (
                    <IonBadge slot="end">Coming Soon</IonBadge>
                  )}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          {appPages2.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={(location.pathname.includes(appPage.url) && appPage.url !== '/') ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                  {appPage.comingSoon && (
                    <IonBadge slot="end">Coming Soon</IonBadge>
                  )}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
