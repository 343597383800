import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { IonIcon ,IonButton, IonItem, IonLabel, IonList, IonChip,IonSpinner , IonBackButton, IonSkeletonText,IonCol, IonGrid, IonRow, useIonViewDidEnter,useIonViewWillLeave, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { GetContent } from '../../services/Api';
import { fastFood, heart, shield, flame, snow, medkit, footsteps, search } from 'ionicons/icons';
import {decode} from 'html-entities';
import '../items/Category.css';
import {Helmet} from "react-helmet";
import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/react';
import ItemList from '../../components/ItemList';
import NotAssociated from '../../components/NotAssociated';

const SinglePostPage = () => {

    const { name } = useParams<{ name: string; }>();
    const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({});
    console.log( name);

    

    const getItem = () => {
        const slug = name;
        console.log('Getting data')
        GetContent(slug).then((data:any) => {
          setIsLoading(false);
          console.log(data);
          setData(data);
        })
    };


    const formatDate = (inputDate: string) => {
      const date = new Date(inputDate);
      const day = date.getDate();
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? 'PM' : 'AM';
  
      // Convert 24-hour format to 12-hour format
      const displayHours = hours % 12 || 12;
      const displayMinutes = minutes < 10 ? '0' + minutes : minutes;
  
      return `${day}th ${month} ${year} at ${displayHours}:${displayMinutes}${amOrPm}`;
  }
  

    useEffect(() => {
        getItem();
        console.log('ionViewDidEnter event fired');
      },[]);
   return ( 
    <IonPage>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{'BlockBook Guide | ' + data.post_title}</title>
        </Helmet>
    <IonHeader>
      <IonToolbar>

      
      <IonButtons slot="start">
          <IonBackButton></IonBackButton>
        </IonButtons>
        {data.post_title ? (
            <IonTitle>{data.post_title}</IonTitle>
        ):(
            <IonSpinner name="dots"></IonSpinner>
        )}
        

        <IonButtons slot="end">
            <IonButton routerLink={"/search"}>
                <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
            </IonButton>
            <IonMenuButton />
        </IonButtons>
        
      </IonToolbar>
    </IonHeader>

    <IonContent fullscreen>
      <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">Items</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className='cont'>
      
      {isLoading ? (
        <IonSkeletonText animated={true} style={{ width: '100%', height:300 }}></IonSkeletonText>
      ) : (
        <>
        <IonGrid>
          <IonRow>
            <IonCol size='12'>
                <IonBreadcrumbs>
                    <IonBreadcrumb routerLink={"/"}>Home</IonBreadcrumb>
                    <IonBreadcrumb routerLink={"/news"}>News</IonBreadcrumb>
                    <IonBreadcrumb>{data.post_title}</IonBreadcrumb>
                </IonBreadcrumbs>
            </IonCol>
            <IonCol size="12">
    
            <span style={{display:'block', padding:'0 10px'}}>
              {data.data.main_image ? (
                  <IonImg className={'image-tint'} alt={data.post_title} src={data.data.main_image.sizes.large} style={{width:'100%', height:'auto'}}/>
                ) : (
                  <IonImg className={'image-tint'} alt={data.post_title} src="/assets/missing-wide.png" />
                )}
            </span>
          

            </IonCol>
              <IonCol size="12"> 
                <IonCard>
                <IonCardHeader>
                  <IonCardTitle>{data.post_title}</IonCardTitle>
                  <IonCardSubtitle><small>Posted: {formatDate(data.date)}</small></IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>

                <div className="content" dangerouslySetInnerHTML={{__html: data.post_content}}></div>

                </IonCardContent>
              </IonCard>              
            </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <NotAssociated />
              </IonCol>
            </IonRow>
          </IonGrid>
          
        
        
        </>
        
      )}
      
          
      </div>
    </IonContent>
  </IonPage>
   );
};


export default SinglePostPage;