import React, { useState, useEffect } from 'react';
import { IonImg,IonIcon, IonButton, IonAvatar, IonChip ,IonItemDivider, IonItemGroup, IonItem, IonLabel, IonList, IonListHeader, IonBackButton, IonSkeletonText, useIonViewDidEnter,useIonViewWillLeave, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import '../Page.css';
import { GetContentList } from '../../services/Api';
import '../items/Category.css';
import {Helmet} from "react-helmet";
import { search } from 'ionicons/icons';


interface PostCategoryPageProps{
  name:string,
  slug:string
}

type Item = {
  name: string;
  data:ItemData;
  rarity: 'common' | 'uncommon' | 'rare' | 'epic';
};

type ItemData = {
  rarity: 'common' | 'uncommon' | 'rare' | 'epic';
}

type RarityOrder = {
  [key in Item['rarity']]: number;
};

const rarityOrder: RarityOrder = {
  common: 0,
  uncommon: 1,
  rare: 2,
  epic: 3,
};

const sortByRarity = (a: Item, b: Item): number => {
  return rarityOrder[a.data.rarity] - rarityOrder[b.data.rarity];
};

const PostCategoryPage = (props:PostCategoryPageProps) => {
    //const { category } = useParams<{ category: string; }>();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [catData, setCatData] = useState<any>({});
  const history = useHistory();

  const getItems = () => {
      GetContentList().then((data:any) => {
        setIsLoading(false);
        const arr:any = Object.values(data);
        console.log(arr);
        setData(arr);
        //sortData(arr);
      })
  };

 

  const viewCategory = (item:any) => {
    history.push("/news/"+item.slug)
  };

//   useIonViewWillLeave(() => {
//     setData([]);
//     setIsLoading(true);
//   });
  useEffect(() => {
    getItems();
    console.log('ionViewDidEnter event fired');
  },[]);
  return (
    <IonPage>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{'BlockBook Guide | News'}</title>
      </Helmet>
      <IonHeader >
        <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton></IonBackButton>
        </IonButtons>
         
          <IonTitle>News</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink={"/search"}>
                <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
            </IonButton>
            <IonMenuButton />
        </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
            <IonButtons slot="start">
                
            </IonButtons>
          <IonToolbar>
            <IonTitle size="large">News</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='cont'>

        {isLoading ? (
          <IonSkeletonText animated={true} style={{ width: '100%', height:300 }}></IonSkeletonText>
        ) : (
          <>
          <IonList >
            {data.map((d:any,ind:number) =>
              <IonItem 
              button 
              key={ind}
              detail={true}  
              onClick={() => {
                viewCategory(d)
              }}
              className={"item--loading"}>
                <IonAvatar slot="start" className={"avatar-item avatar--loading"}>
                {d.data.main_image ? (
                  <IonImg alt={d.post_title} src={d.data.main_image.sizes.thumbnail} />
                ) : (
                  <IonImg alt={d.post_title} src="/assets/missing.png" />
                )}
                  
                </IonAvatar>
                <IonLabel>
                  <h4>{d.post_title}</h4>
                  <small>Posted: {d.date}</small>
                </IonLabel>
              </IonItem>
            )}
            <IonItemDivider></IonItemDivider>
          </IonList>
          </>
          
        )}
        
            
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PostCategoryPage;
