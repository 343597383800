import React from "react";
import { useHistory } from 'react-router-dom';
import {IonCard,
    IonImg,
    IonCardHeader,
    IonCardTitle,
    IonList,
    IonItem,
    IonAvatar,
    IonChip,
    IonLabel,
    IonBadge,
    IonCardContent
    } from '@ionic/react';


interface ItemListProps {
    title:string,
    data:Array<any>
}
const ItemList = (props:ItemListProps) => {
    const history = useHistory();
    const viewItem = (item:any) => {
        history.push("/items/"+item.cat.slug+"/"+item.post_name)
      };

    return (
        <>
        {props.data.length > 0 && (
        <IonCard>
        <IonCardHeader>
          <IonCardTitle>{props.title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
          {props.data.map((req:any, indr:number) => (
            <IonItem 
            button 
            key={'req'+indr}  
            onClick={() => {
              viewItem(req)
            }}
            className={"item--"+req.rarity}>
              <IonAvatar slot="start" className={"avatar-item avatar--"+req.rarity}>
                {req.thumbnail ? (
                  <IonImg
                  alt={req.post_title} src={req.thumbnail}
                ></IonImg>
                ) : (
                <IonImg
                  alt={req.post_title} src="/assets/missing.png"
                ></IonImg>
                )}
              </IonAvatar>
              <IonLabel>
              

                <h4>{req.post_title}</h4>
                {req.notes && (
                  <small style={{display:'block', margin:"5px 0 2px"}}>{req.notes}</small>
                )}
                <IonChip className={"bb-chip bb-chip--"+req.rarity}>{req.rarity[0].toUpperCase() + req.rarity.substring(1)}</IonChip>
              </IonLabel>
              {req.quantity && (
                <IonBadge slot="end" className={"large-badge bb-chip bb-chip--"+req.rarity}><small>x</small> {req.quantity}</IonBadge>
              )}
              
            </IonItem>
          ))}
          </IonList>
        </IonCardContent>
      </IonCard>

      )}
        
        </>
    );
    
}

export default ItemList;