import React from 'react';
import { IonText, IonIcon, IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import './Page.css';
import { search } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

const Page404: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            <IonButton routerLink={"/search"}>
                <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className='cont404'>
        <div className='graphic-404'></div>
        <IonText color="white">
          <h1 className='luckiest' style={{fontSize:'2em', marginBottom:'1em'}}>The page you were looking for was not found</h1>
        </IonText>
        <IonButton onClick={() => {
          history.push("/");
        }}>
          Go To Homepage
        </IonButton>
        </div>
        
      </IonContent>
    </IonPage>
  );
};

export default Page404;
