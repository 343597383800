import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { IonIcon ,IonButton, IonItem, IonLabel, IonList, IonChip,IonSpinner , IonBackButton, IonSkeletonText,IonCol, IonGrid, IonRow, useIonViewDidEnter,useIonViewWillLeave, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { GetObject } from '../../services/Api';
import { fastFood, heart, shield, flame, snow, medkit, footsteps, search } from 'ionicons/icons';
import {decode} from 'html-entities';
import '../items/Category.css';
import {Helmet} from "react-helmet";
import { IonBreadcrumb, IonBreadcrumbs } from '@ionic/react';
import ItemList from '../../components/ItemList';
import NotAssociated from '../../components/NotAssociated';

const SingleMobPage = () => {

    const { category } = useParams<{ category: string; }>();
    const { name } = useParams<{ name: string; }>();
    const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({});
    console.log(category, name);

    
  const keyList = [
    {key:'health', icon:heart, label:'Health'},
    {key:'hunger', icon:fastFood, label:'Hunger'},
    {key:'defense', icon:shield, label:'Defense'},
    {key:'damage', icon:shield, label:'Damage'},
    {key:'add_health', icon:heart, label:'Additional Health'},
  ];


  const buffList = [
    {key:'heat', icon:flame, label:'Heat Resistance'},
    {key:'cold', icon:snow, label:'Cold Resistance'},
    {key:'health_over_time', icon:medkit, label:'Health Over Time'},
    {key:'stamina', icon:footsteps, label:'Stamina'},
  ];

    const getItem = () => {
        const slug = name;
        console.log('Getting data')
        GetObject(slug).then((data:any) => {
          setIsLoading(false);
          console.log(data);
          setData(data);
        })
    };

    const toHtmlEntities = (str:string) => {
      if(str && typeof str === 'string') {
        // strip script/html tags
        const temp = decode(str);
        str = temp.replace(/<\/?[^>]+(>|$)/g, "");
      }
  
      return str;
    };

    const secondsToMinutes = (seconds: number) => {
      return seconds / 60;
    };

    useEffect(() => {
        getItem();
        console.log('ionViewDidEnter event fired');
      },[]);
   return ( 
    <IonPage>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{'BlockBook Guide | ' + data.post_title}</title>
            <meta name="description" content={'Read more about ' + data.post_title + ', an mob within Fortnite Lego'}></meta>
        </Helmet>
    <IonHeader>
      <IonToolbar>

      
      <IonButtons slot="start">
          <IonBackButton></IonBackButton>
        </IonButtons>
        {data.post_title ? (
            <IonTitle>{data.post_title}</IonTitle>
        ):(
            <IonSpinner name="dots"></IonSpinner>
        )}
        

        <IonButtons slot="end">
            <IonButton routerLink={"/search"}>
                <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
            </IonButton>
            <IonMenuButton />
        </IonButtons>
        
      </IonToolbar>
    </IonHeader>

    <IonContent fullscreen>
      <IonHeader collapse="condense">
          <IonButtons slot="start">
              
          </IonButtons>
        <IonToolbar>
          <IonTitle size="large">Items</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className='cont'>
      
      {isLoading ? (
        <IonSkeletonText animated={true} style={{ width: '100%', height:300 }}></IonSkeletonText>
      ) : (
        <>
        <IonGrid>
          <IonRow>
            <IonCol size='12'>
                <IonBreadcrumbs>
                    <IonBreadcrumb routerLink={"/"}>Home</IonBreadcrumb>
                    <IonBreadcrumb routerLink={"/mobs"}>Mobs</IonBreadcrumb>
                    <IonBreadcrumb>{data.post_title}</IonBreadcrumb>
                </IonBreadcrumbs>
            </IonCol>
            <IonCol size="12" sizeMd='4'>
            <IonCard>

            <span className={"card-image card--"+data.data.rarity}>
              {data.data.thumbnail ? (
                  <IonImg className={'image-tint'} alt={data.post_title} src={data.data.thumbnail} />
                ) : (
                  <IonImg className={'image-tint'} alt={data.post_title} src="/assets/missing-wide.png" />
                )}
                </span>
              <IonCardHeader>
                
                <IonCardTitle style={{paddingRight:"60px"}}>{data.post_title}  <IonChip className={"bb-right bb-chip bb-chip--"+data.data.rarity}>{data.data.rarity}</IonChip></IonCardTitle>
                <IonCardSubtitle>   
                  <i>{toHtmlEntities(data.data.description)}</i>               
                 
        </IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                {data.post_content}
                
              </IonCardContent>
            </IonCard>
            </IonCol>
              <IonCol size="12" sizeMd='8'> 

              {data.data.tips && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Tips</IonCardTitle>
                    {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                  </IonCardHeader>

                  <IonCardContent>
                  {toHtmlEntities(data.data.tips)}

                  </IonCardContent>
                </IonCard>
              )}


            {data.data.where_to_find && (
                <IonCard>   
                  <IonCardHeader>
                    <IonCardTitle>Where to find</IonCardTitle>
                    {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                  </IonCardHeader>

                  <IonCardContent>
                  {toHtmlEntities(data.data.where_to_find)}

                  </IonCardContent>
                </IonCard>
              )}

              
                

                  <ItemList data={data.data.items_dropped} title={"Items Dropped"} />
              
            </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <NotAssociated />
              </IonCol>
            </IonRow>
          </IonGrid>
          
        
        
        </>
        
      )}
      
          
      </div>
    </IonContent>
  </IonPage>
   );
};


export default SingleMobPage;