//http://legoapi.local/wp-json/lego-api/v1/objects?c=weapons
//http://legoapi.local/wp-json/lego-api/v1/categories?c=items
const BASE_DEV_URL = 'http://legoapi.local/wp-json/lego-api/v1';
const BASE_URL = 'https://api.blockbook.guide/wp-json/lego-api/v1';

const GetBaseUrl = () => {
    // if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
    //     return BASE_DEV_URL;
    // }

    return BASE_URL;
}

const GetCategories = async (slug:string) => {
    const apiUrl = GetBaseUrl()+'/categories?c='+slug;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

const GetObjects = async (slug:string) => {
    const apiUrl = GetBaseUrl()+'/objects?c='+slug;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

const GetObject = async (slug:string) => {
    const apiUrl = GetBaseUrl()+'/object?slug='+slug;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

const SearchObjects = async (slug:string) => {
    const apiUrl = GetBaseUrl()+'/search/'+slug;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

const GetHomeObject = async () => {
    const apiUrl = GetBaseUrl()+'/home';
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

const GetContentList = async () => {
    const apiUrl = GetBaseUrl()+'/content';
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

const GetContent = async (slug:string) => {
    const apiUrl = GetBaseUrl()+'/content/get?slug='+slug;
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
};

export {
    GetContentList,
    GetContent,
    GetHomeObject,
    GetCategories,
    GetObjects,
    GetObject,
    SearchObjects
};
