import React, { useState, useEffect } from 'react';
import { IonButton, IonIcon, IonSkeletonText, useIonViewDidEnter, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';

import {Helmet} from "react-helmet";
import { search } from 'ionicons/icons';

import '../Page.css';
import { GetCategories } from '../../services/Api';
import { useHistory } from 'react-router-dom';
import NotAssociated from '../../components/NotAssociated';

interface ParentCategoryPageProps{
  name:string,
  slug:string
}
const ParentCategoryPage = (props:ParentCategoryPageProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [header, setHeader] = useState<any>('BlockBook Guide | ' + props.name);
  
  const getCategories = () => {
      const slug = props.slug;
      console.log('Getting '+props.name)
      GetCategories(slug).then((data:any) => {
        setIsLoading(false);
        console.log(data);
        const arr:any = Object.values(data);
        setData(arr);
        console.log(arr);
      })
  };

  const viewCategory = (slug:string) => {
    console.log(slug);

    history.push("/"+props.slug+"/"+slug)
  };

  useEffect(() => {

    getCategories();
  },[]);
  return (
    <IonPage>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{header}</title>
          <meta name="description" content={'Read more about items within Fortnite Lego on Blockbook guide'}></meta>

      </Helmet>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="end">
            <IonButton routerLink={"/search"}>
                <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
            </IonButton>
            <IonMenuButton />
        </IonButtons>
          <IonTitle>{props.name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{props.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='cont'>

        {isLoading ? (
          <IonSkeletonText animated={true} style={{ width: '100%', height:300 }}></IonSkeletonText>
        ) : (
          <>

          {data.map((d:any,ind:number) => (
             <IonCard key={"card"+ind}  style={{cursor:'pointer'}} onClick={() => {
        viewCategory(d.slug)
        }}>
              <IonCardHeader>
                  <IonCardTitle>{d.cat_name}</IonCardTitle>
                  <IonCardSubtitle>{d.description}</IonCardSubtitle>
              </IonCardHeader>
{/* */}
          </IonCard> 
          ))}
          
          
          </>
          
        )}
        
        <NotAssociated />  
        </div>

        
      </IonContent>
    </IonPage>
  );
};

export default ParentCategoryPage;
