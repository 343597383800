import React, { useState } from 'react'; 
import { useHistory } from 'react-router-dom';
import { IonInput, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonRow, IonCol, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonButton, IonIcon } from '@ionic/react';
import { search} from 'ionicons/icons';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import BannerComponent from '../components/Banner/Banner';
import {Helmet} from "react-helmet";
import NotAssociated from '../components/NotAssociated';

const Home: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const submitSearch = () => {
    history.push("/search?s="+searchTerm);
  };
  return (
    <IonPage>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{'BlockBook Guide'}</title>
            <meta name="description" content="The Block Book Guide is a comprehensive app designed to provide users with detailed information on items, weapons, tools, resources, and mobs within the Fortnite Lego universe."></meta>

        </Helmet>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            
            <IonButton routerLink={"/search"}>
                    <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
                </IonButton>
                <IonMenuButton />
          </IonButtons>

            
          {/* <IonTitle>Home</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            {/* <IonTitle size="large">Home</IonTitle> */}
            <IonButtons slot="primary">
                <IonButton>
                    <IonIcon slot="icon-only" ios={search} md={search}></IonIcon>
                </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className='cont-tight'>
          
          <IonGrid>
            <IonRow>
              <IonCol size='12'>
              
                <IonCard color={'primary'} style={{marginBottom:15}}>
                  <IonCardHeader>
                    <IonCardTitle><h1 style={{marginBottom:0,marginTop:5, fontSize:'2.25em', fontFamily:"'Luckiest Guy', cursive"}}>Welcome to Block Book Guide</h1></IonCardTitle>
                    <IonCardSubtitle><h2 style={{marginTop:0,marginBottom:0, fontFamily:"'Luckiest Guy', cursive", opacity:.7}}>The Unofficial Fortnite Lego Guide Book</h2></IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent style={{paddingBottom:25}}>The Block Book Guide is a comprehensive app designed to provide users with detailed information on items, weapons, tools, resources, and mobs within the Fortnite Lego universe.</IonCardContent>
                </IonCard>

                <IonCard>
                  <IonCardContent>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='7' sizeSm="9" sizeMd="10">
                        <IonInput label={"Search"} placeholder="Axe, Frostpine, Marble" onIonInput={(e:any) => {
                            setSearchTerm(e.detail.value)
                        }}></IonInput>
                      </IonCol>
                      <IonCol size='5' sizeSm="3" sizeMd="2">
                      <IonButton 
                      onClick={submitSearch}
                      disabled={searchTerm.length < 3}>
                        <IonIcon slot="end" icon={search}></IonIcon>
                        Search
                      </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  </IonCardContent>
                </IonCard>
                <div style={{margin:'0 10px'}}>
                  <BannerComponent />
                </div>
                

              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='6'>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Equipment</IonCardTitle>
                    <IonCardSubtitle>Shields, Torches, Gadgets, Trinkets and Charms!</IonCardSubtitle>
                  </IonCardHeader>
                  <IonButton 
                    fill="clear"
                    routerLink={"/items/equipment"}
                    >View Equipment</IonButton>
                </IonCard>
              </IonCol>
              <IonCol size='6'>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Weapons</IonCardTitle>
                    <IonCardSubtitle>Bows, Projectiles and Swords</IonCardSubtitle>
                  </IonCardHeader>
                  <IonButton 
                    fill="clear"
                    routerLink={"/items/weapons"}
                    >View Weapons</IonButton>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='6'>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Resources</IonCardTitle>
                    <IonCardSubtitle>Creature Resources, Raw And Processed Resources</IonCardSubtitle>
                  </IonCardHeader>
                  <IonButton 
                    fill="clear"
                    routerLink={"/items/resources"}
                    >View Resources</IonButton>
                </IonCard>
              </IonCol>
              <IonCol size='6'>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Mobs</IonCardTitle>
                    <IonCardSubtitle>Docile and Hostile Creatures aswell as Scoundrels and Skeletons</IonCardSubtitle>
                  </IonCardHeader>
                  <IonButton 
                    fill="clear"
                    routerLink={"/mobs"}
                    >View Mobs</IonButton>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <NotAssociated />
              </IonCol>
            </IonRow>

          </IonGrid>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Home;
