import { IonCardContent, IonCard, IonCardTitle, IonCardHeader } from "@ionic/react";
import React from "react";
const NotAssociated = () => {
    return (
        <>
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>About us</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
            We are not associated with Fortnite, Epic Games or Lego in anyway. All data is provided for informative purposes and belongs to them.
            </IonCardContent>
        </IonCard>
                
        </>
    );
}

export default NotAssociated;